import React, {useState, useEffect} from 'react'
import IconRow from './IconRow';
import Photo from '../img/LauraRaaj.jpg'
import imraaj from '../im-raaj.svg';
import imlaura from '../im-laura.svg';
import _, { set } from 'lodash';

function LauraReasons (props) {
    const [currentButton, setCurrentButton] = useState(1);
    const [done, _setDone] = useState(true);
    const setDone = (done) => {
        _setDone(done);
        setShowUnderlying(true);
    }
    const [showUnderlying, setShowUnderlying] = useState(false);
    const [hoverRaura, _setHoverRaura] = useState(false);
    const setHoverRaura = (hoverRaura) => {
        _setHoverRaura(hoverRaura);
        if (hoverRaura) setRedOrGreen(_.sample([true,false]));
    }
    const [redOrGreen, setRedOrGreen] = useState(false);

    useEffect(() => {
        setDone(false);
    }, []);

    function handleChangeHover (selectedButton) {
        setCurrentButton(selectedButton);
    }

    return (
        <>
            <div className={done ? "d-none": "laura-reasons"}>
                <h1>36 Reasons why Laura is the most radiant supernova in the universe:</h1>
                <section className="reasons-section">
                    <div className="reasons-grid">
                        { 
                            [...Array(6)].map((x,i) => {
                                return (
                                    <IconRow start={6*i} end={6*i+6} data={props.data} rowKey={i} onChangeHover={handleChangeHover}/>
                            )})}
                    </div>
                    <div className="reasons-display">
                        <h1 className='title'>{props.data[currentButton-1].reasonIcon}</h1>
                        <h1>{props.data[currentButton-1].reasonHeader}</h1>
                        <p>{props.data[currentButton-1].reasonBody}</p>
                        {currentButton === props.data.length ? 
                        <button className='done-button' onClick={() => {setDone(true)}}>Done</button> :
                        null
                        }
                    </div>
                </section>
            </div>
            <div className={done && showUnderlying ? "final-photo" : "d-none"}>
                <img className='laura-raaj-photo' src={Photo} alt='Laura and Raaj'
                onMouseEnter={() => setHoverRaura(true)} onMouseLeave={() => setHoverRaura(false)}/>
                <div class='i-am'>
                    <img class='i-am-img' src={redOrGreen ? imraaj : imlaura} alt='' />
                    <h1>Raura</h1>
                </div>
                <h1 className='title'>
                    I love you Laura
                </h1>
            </div>
        </>
    )
}

export default LauraReasons;