import React, {useState} from 'react';

function IconButton (props) {
    const [hovered, _setHovered] = useState(false);
    const setHovered = (hovered) => {
        _setHovered(hovered);
        props.onChangeHover(props.number);
    }

    return (
        <button className='icon-button'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >{!hovered ? props.number : props.icon}</button>
    )
}

export default IconButton;