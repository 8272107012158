import React from 'react';
import IconButton from './IconButton';

function IconRow (props) {
    return (
        <div className="row" key={props.rowId}>
        {props.data.slice(props.start,props.end).map((item, ii) => (
            <IconButton key={props.data.id} number={item.id} icon={item.reasonIcon} onChangeHover={props.onChangeHover} />
        ))}
        </div>
    )    
}

export default IconRow