import React from 'react';
import '../css/App.css';
import { useForm } from 'react-hook-form';
import IconButton from './IconButton';

function StandardPage(props) {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = () => props.onChangePage(props.inputs.nextPage);
    console.log(errors);

    // Pass next page to the parent application
    function handleChangePage(event) {
        props.onChangePage(event.target.dataset.nextpage);
    }

    return (      
        <section className='content-section'>
            {props.inputs.id === 5 ?
            <p>“01101001  00100000 01101100 01101111 01110110 01100101 00100000 01111001 01101111 01110101 00100000 01110100 01101111 01101111” -Raajbot</p>
            : null
            }
            <h1 className='title'>
                {props.inputs.titleText}
            </h1>
            {props.inputs.showIcons ? 
                <div className="icon-button-container">
                    <IconButton number='1' icon='😍' onChangeHover={(id)=>{return}}/>
                    <IconButton number='2' icon='🤗' onChangeHover={(id)=>{return}}/>
                    <IconButton number='3' icon='🤔' onChangeHover={(id)=>{return}}/>
                    <IconButton number='4' icon='😡' onChangeHover={(id)=>{return}}/>
                    <IconButton number='5' icon='🤬' onChangeHover={(id)=>{return}}/>
                    <IconButton number='6' icon='🤤' onChangeHover={(id)=>{return}}/>
                </div>
            : null
            }
            <div className='form-container'>
                {props.inputs.question ? 
                    // If question then create form
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <input id='input-box' type="text" placeholder="Answer" name="answer" ref={
                            // Input validation
                            register({required: true, pattern: new RegExp(`${props.inputs.answer}`,'i')})
                        } />
                    <input id='submit-box' className="submit-button" type="submit" />
                    <h1>{errors.answer && wrongContent[Math.floor(Math.random() * wrongContent.length)]}</h1>
                    </form>
                :
                    // If not question then button is sufficient
                    <button data-nextpage={props.inputs.nextPage}
                        onClick={handleChangePage}
                    >{props.inputs.buttonText}</button>
                } 
                
            </div>
        </section>
    )
}

let wrongContent = ["Wrongggg", "U silly", "Lauraaaa What the Heckkkkk", "I thought you would know me better", "Grrrrrr"];

export default StandardPage;