import React, {useState} from 'react';
import '../css/App.css';
import imraaj from '../im-raaj.svg';
import imlaura from '../im-laura.svg';

function Intro (props) {
    const [lauraText, setLauraText] = useState(false);
    const [raajText, setRaajText] = useState(false);

    function handleChangePage(event) {
        props.onChangePage(event.target.dataset.nextpage);
    }

    return(
        <header className="intro-section">
            <div className='intro-text'>
                <img src={imlaura} alt=''
                    className = {
                    'im-text ' + (lauraText ?  null : 'd-transparent')
                    }/>
                <h1 data-nextpage='1' className='name-link' id='laura-link' 
                    onMouseEnter={() => setLauraText(true)} 
                    onMouseLeave={() => setLauraText(false)}
                    onClick={handleChangePage}
                >
                    Laura
                </h1>
            </div>
            <div className='intro-text'>
                <img src={imraaj} alt=''
                    className = {
                    'im-text ' + (raajText ?  null : 'd-transparent')
                    }/>
                <h1 data-nextpage='-1' className='name-link' id='raaj-link'
                    onMouseEnter={() => setRaajText(true)} 
                    onMouseLeave={() => setRaajText(false)}
                    onClick={handleChangePage}
                > 
                    Raaj
                </h1>
            </div>
        </header>
    )
}

export default Intro;