import React, {useState, useEffect} from 'react';
import './css/App.css';
import Intro from './components/Intro';
import ErrorPage from './components/ErrorPage';
import StandardPage from './components/StandardPage';
import LauraReasons from './components/LauraReasons';
import {Animated} from "react-animated-css";

function App() {
  // Initialize state for data
  const [data, setData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  // Initialize state for current page
  const [currentPage, _setCurrentPage] = useState(0);
  const [animationState, setAnimationState] = useState(true);
  const setCurrentPage = (currentPage) => {
    _setCurrentPage(currentPage);
  }

  // Get data from file
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('./data.json')
      const json = await response.json();
      
      setData(json);
    };

    fetchData();
  }, []);

    // Get data from file
    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('./reasons.json')
        const json = await response.json();
        
        setReasonsData(json);
      };
  
      fetchData();
    }, []);

  // Handle changing pages
  function handleChangePage (nextPage) {
    setCurrentPage(parseInt(nextPage));
  }
  
  // Render the correct page based on the state
  function renderPage(currentPage) {
    switch(true) {
      case (currentPage === 0):
        return <Intro onChangePage={handleChangePage}/>;
      case (currentPage > 0 && currentPage <= data.length):
        return (
          // Animate the first slide
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={animationState}>
            <StandardPage inputs={data[currentPage-1]} onChangePage={handleChangePage} />
          </Animated>
        )
      case (currentPage > data.length):
        return (
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={animationState}>
            <LauraReasons data={reasonsData} />
          </Animated>
        )
      default:
        return <ErrorPage onChangePage={handleChangePage}/>;
    }
  }

  return (
    <div className="App">
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={animationState}>
        {renderPage(currentPage)}
      </Animated>
    </div>
  );
}

export default App;
