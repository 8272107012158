import React from 'react';
import '../css/App.css';

function ErrorPage(props) {
    function handleChangePage(event) {
        props.onChangePage(event.target.dataset.nextpage);
        console.log(event.target.dataset.nextpage);
    }
    return (
        <section className='content-section'>
            <h1 className='title'>
                You're not supposed to be here!
            </h1>
            <button data-nextpage={0}
                onClick={handleChangePage}
            >Go Back!</button>
        </section>
    )
}

export default ErrorPage;